import React, { forwardRef, useImperativeHandle } from 'react';

const Conversation = forwardRef(({ isActive, onMessageReceived, onStatusChange }, ref) => {
  useImperativeHandle(ref, () => ({
    startRecording: async () => {
      // This is now handled by the widget
      console.log('Recording handled by ElevenLabs widget');
    },
    stopRecording: async () => {
      // This is now handled by the widget
      console.log('Recording stopped by ElevenLabs widget');
    }
  }));

  return null;
});

export default Conversation;

